<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <div v-if="academy !== null">
      <b-modal
          id="modal-delete"
          cancelTitle="خیر"
          centered
          ok-title="بله"
          @ok="deleteAcademy"
      >
        <span>آیا از حذف کردن این دوره اطمینان دارید ؟ </span>
      </b-modal>

      <!-- Table Container Card -->
      <b-card
          v-if="academy"
          class="mb-0"
          no-body
      >
        <div class="ecommerce-searchbar p-1">
          <b-row>
            <b-col cols="12" md="4">
              <b-input-group class="input-group-merge">
                <debouncer :placeHolder="`جستجوی دوره ها`" @setValue="getsearchValue"></debouncer>

              </b-input-group>
            </b-col>

            <b-col
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                cols="12"
                md="4"
            >
              <label class="mr-2">دسته بندی</label>
              <v-select
                  v-model="categoryId"
                  :clearable="false"
                  :options="academyCategories"
                  :reduce="name => name.academyCategoryId"
                  class="per-page-selector w-75"
                  label="name"
              />
            </b-col>
            <b-col
                class="d-flex align-items-center justify-content-start px-0  mb-1 mb-md-0"
                cols="12"
                md="2"
            >
              <b-button v-if="selectedIds.length>0" class="d-flex align-items-center gap-2" variant="primary"
                        @click="copyObjects">
                <feather-icon icon="CopyIcon"></feather-icon>
                <small class="px-25">کپی</small>
              </b-button>
              <b-button v-if="selectedIds.length>0" class="mx-25" variant="success" @click="changeIsInStock">
                <small>تغییر وضعیت موجودی</small>
              </b-button>
            </b-col>
            <b-col cols="12" lg="2">
              <clear-sorts-btn @clear="sortBy=''"></clear-sorts-btn>

            </b-col>
          </b-row>
        </div>


        <b-table
            ref="refUserListTable"
            :fields="myTableColumns"
            :items="academy"
            bordered
            :sort-by.sync="sortBy"
            class="position-relative"
            empty-text="موردی موجود نیست!"
            primary-key="id"
            responsive
            show-empty
            striped
        >

          <template #cell(academyId)="data">
            <a :href="returnUrl+ `academy/${data.item.academyId}/${data.item.seourl}`" target="_blank">
              <b-avatar v-if="data.item.photos.length>0"
                        :src="baseURL+'media/gallery/academy/'+data.item.photos[0].base64" size="100"
                        square/>
              <span class="text-muted ml-2">@{{ data.item.academyId }}</span>
            </a>

          </template>


          <template #cell(isInStock)="data">
            <b-badge v-if="data.item.isInStock" variant="primary">موجود</b-badge>
            <b-badge v-else variant="danger">ناموجود</b-badge>

          </template>
          <template #cell(createDate)="data">
            <small>{{ new Date(data.item.createDate).toLocaleDateString('fa-IR') }}</small>
          </template>
          <template #cell(status)="data">
            <b-badge v-if="data.item.status===2" variant="primary">منتشر شده</b-badge>
            <b-badge v-if="data.item.status===1" variant="secondary">در انتظار تایید</b-badge>
            <b-badge v-if="data.item.status===3" variant="danger">رد شده</b-badge>

            <b-dropdown
                no-caret
                variant="link"
            >
              <template #button-content>
                <feather-icon
                    class="align-middle text-body"
                    icon="ChevronDownIcon"
                    size="16"
                />
              </template>

              <b-dropdown-item @click="changeAcademyStatus(data.item.academyId,2)">
                <span class="align-middle ml-50">منتشر شده</span>
              </b-dropdown-item>

              <b-dropdown-item @click="changeAcademyStatus(data.item.academyId,1)">
                <span class="align-middle ml-50">در انتظار تایید</span>
              </b-dropdown-item>

              <b-dropdown-item @click="changeAcademyStatus(data.item.academyId,3)">
                <span class="align-middle ml-50">رد شده</span>
              </b-dropdown-item>


            </b-dropdown>


          </template>
          <template #cell(academyId)="data">
            <b-link :to="{   name: 'pages-academy-MyStudents', params: { id: data.item.academyId } }">
              <feather-icon class="text-primary" icon="EyeIcon" size="20"/>
            </b-link>
          </template>


          <template #cell(operation)="data">
            <div class="d-flex align-items-center justify-content-between">

              <b-link :to="{ name: 'pages-academy-AcademyEdit', params: { id: data.item.academyId } }">
                <feather-icon class="text-primary" icon="EditIcon" size="20"/>
              </b-link>
              <span class="cursor-pointer"
                    @click.prevent="$router.push({ name: 'apps-e-commerce-add-product-media', params: { id: data.item.academyId }})">
                <feather-icon class="text-primary" icon="CastIcon" size="20"/>
              </span>
              <div v-b-modal.modal-delete
                   class="cursor-pointer d-flex flex-row"
                   @click="SetSelectedAcademy(data.item)"
              >
                <feather-icon class="text-danger" icon="TrashIcon" size="20"/>
              </div>
              <b-form-checkbox
                  v-model="selectedIds"
                  :value="data.item.academyId"
              />
            </div>

          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <!-- Pagination -->
            <b-col
                class="d-flex align-items-center justify-content-end "
                cols="12"
                sm="12"
            >

              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalCount"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon"
                                size="18"
                                style="transform: rotate(180deg)"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>


  </b-overlay>
</template>

<script>

import {

  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormFile,
  BBadge, BDropdown, BDropdownItem, BInputGroup, BPagination, BOverlay, BModal, BFormGroup, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {
  DeleteAcademy,
  GetAcademyForVendor,
  AcademyCategoryGetAllRequest,
  ChangeIsInStock,
  CopyAcademyObjects,
  ChangeAcademyStatus
} from "@/libs/Api/academy";
import Helper from "@/libs/Helper";
import ClearSortsBtn from "@/views/components/clearSortsBtn.vue";
import Debouncer from "@/views/components/debouncer.vue";


export default {
  title: 'لیست  دوره ها  - پنل ادمین انزا مد',
  name: 'AcademyList',
  data() {
    return {
      baseURL: Helper.baseUrl,
      returnUrl: Helper.returnUrl,
      academy: null,
      totalCount: null,
      showOverlay: false,
      selectedIds: [],
      sortBy: '',
      academyCategories: [
        {
          name: 'همه',
          academyCategoryId: 0
        }
      ],
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {
          key: 'academyId',
          label: 'شناسه',
          sortable: true
        },
        {
          key: 'title',
          label: 'نام',
          sortable: true
        },
        {
          key: 'createDate',
          label: 'تاریخ ثبت',
          sortable: true
        },
        {
          key: 'categoryName',
          label: 'دسته بندی',
          sortable: true
        },
        {
          key: 'isInStock',
          label: 'وضعیت موجودی',
          sortable: true
        },
        {
          key: 'status',
          label: 'وضعیت انتشار',
          sortable: true
        },
        {
          key: 'academyId',
          label: 'دانشجویان دوره',
          sortable: true
        },

        {
          key: 'operation',
          label: 'عملیات'
        },
        // {
        //   key: 'SEO',
        //   label: 'SEO'
        // },
      ],
      currentPage: 1,
      perPage: 10,
      CategoryName: '',
      search: '',
      categoryId: 0,
      SelectedAcademy: null,
      SelectedCategoryId: 0

    }
  },
  async created() {
    await this.GetAllAcademyForVendor();
    await this.getCategories();
  },
  components: {
    Debouncer,
    ClearSortsBtn,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormSelect,
    BInputGroup,
    vSelect,
  },
  watch: {
    currentPage: function () {
      this.GetAllAcademyForVendor()
    },
    categoryId: function (nv, ov) {
      this.GetAllAcademyForVendor();
    },
  },
  methods: {
    getsearchValue(val) {
      this.search = val
      this.GetAllAcademyForVendor()

    },
    async changeAcademyStatus(id, status) {
      let _this = this;
      _this.showOverlay = true;
      let changeAcademyStatus = new ChangeAcademyStatus(_this)
      changeAcademyStatus.setParams({objectId: id, status: status})
      await changeAcademyStatus.fetch((content) => {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `وضعیت انتشار دوره با موفقیت تغییر یافت`,
          },
        })
        _this.GetAllAcademyForVendor();
      }, (err) => {
        console.log(err)
      })
      _this.showOverlay = false;
    },
    async GetAllAcademyForVendor() {
      let _this = this;
      _this.showOverlay = true;
      let getAcademyForVendor = new GetAcademyForVendor(_this)
      let data = {
        pageNumber: _this.currentPage,
        searchCommand: _this.search,
        count: 10,
        categoryId: _this.categoryId
      }
      getAcademyForVendor.setParams(data)
      await getAcademyForVendor.fetch(function (content) {
        _this.academy = content.academys
        _this.totalCount = content.academysCount
        _this.showOverlay = false;
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    async copyObjects() {
      let _this = this
      if (_this.selectedIds.length > 0) {
        _this.showOverlay = true
        let copyObjects = new CopyAcademyObjects(_this)
        copyObjects.setParams(_this.selectedIds)
        await copyObjects.fetch((content) => {
          _this.GetAllAcademyForVendor();
          _this.selectedIds = []
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات موفق`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `دوره/دوره ها با موفقیت کپی شدند`,
            },
          })
        },)
        _this.showOverlay = false

      } else {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'AlertIcon',
            variant: 'danger',
            text: `لطفا حداقل یک دوره انتخاب کنید`,
          },
        })
      }

    },
    async changeIsInStock() {
      let _this = this
      if (_this.selectedIds.length > 0) {
        _this.showOverlay = true
        let changeIsInStock = new ChangeIsInStock(_this)
        changeIsInStock.setParams(_this.selectedIds)
        await changeIsInStock.fetch((content) => {
          _this.GetAllAcademyForVendor();
          _this.selectedIds = []
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات موفق`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `وضعیت دوره/دوره ها با موفقیت تغییر یافت`,
            },
          })
        },)
        _this.showOverlay = false

      } else {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'AlertIcon',
            variant: 'danger',
            text: `لطفا حداقل یک دوره انتخاب کنید`,
          },
        })
      }
    },
    async getCategories() {
      let _this = this;
      _this.showOverlay = true;
      let academyCategoryGetAllRequest = new AcademyCategoryGetAllRequest(_this);
      await academyCategoryGetAllRequest.fetch(function (content) {
        content.forEach(el => {
          _this.academyCategories.push(el);
        })
        _this.showOverlay = false;
      }, function (error) {
        console.log(error);
        _this.showOverlay = false;
      })
    },
    async deleteAcademy() {
      let _this = this;
      _this.showOverlay = true;
      let deleteAcademy = new DeleteAcademy(_this)
      deleteAcademy.setParams({id: this.SelectedAcademy.academyId})
      await deleteAcademy.fetch(function (content) {
        console.log(content)
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `دوره حذف شد.`,
          },
        })
        _this.GetAllAcademyForVendor();
        _this.showOverlay = false;
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    SetSelectedAcademy(item) {
      this.SelectedAcademy = JSON.parse(JSON.stringify(item))
    },
  },
}
</script>

<style scoped>

</style>
